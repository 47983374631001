import React from 'react';
import BearingDiffCalculator from './windLogic'

class WindDirectionArrow extends React.Component {

    componentDidMount() {
    }

    render() {

        var windIndicationColour = "";

        if (this.props.windingRAGStatus != null) {
            windIndicationColour = new BearingDiffCalculator().getBearingDiffIndicatorColourForWindingRAGStatus(this.props.windingRAGStatus, (this.props.palette != null && this.props.palette === "dark"));
        }
        else {
            windIndicationColour = this.props.molenOrientation != null ? new BearingDiffCalculator().getBearingDiffIndicationColour(this.props.windDirection, this.props.molenOrientation, this.props.windSpeedMs) : "#909090";
            if (this.props.palette != null && this.props.palette === "dark") {
                windIndicationColour = this.props.molenOrientation != null ? new BearingDiffCalculator().getBearingDiffIndicationColourDarker(this.props.windDirection, this.props.molenOrientation, this.props.windSpeedMs) : "#909090";
            }
        }

        var size = this.props.size != null ? this.props.size : 32;

        return (
            <>
                <div className="windDirectionArrow" >
                    <svg height={size} width={size}>

                        <g name="arrow" transform={"scale(" + size / 32 + " )"} >
                            <polygon points="6,4 16,28, 26,4, 16,12" fill={windIndicationColour} transform={"rotate( " + this.props.windDirection + ", 15, 15)"} />
                        </g>

                    </svg>
                </div>

            </>
        )
    }
}



export default WindDirectionArrow