import React from 'react'
import { HomePageTelemetryPanel } from '../homePageTelemetry/HomePageTelemetryPanel';
import './HomeMainPanel.css'
import { HomePanelItem } from './HomePanelItem';
import { SmartmolenMiniMap } from './SmartmolenMiniMap';

class HomeMainPanel extends React.Component {

  componentDidMount() {
  }

  onPageSelect(page) {
    this.props.onPageSelect(page);
  }

  onTelemetryPanelSelect() {
    this.props.onTelemetryPanelSelect();
  }

  render() {

    var svgDotSize = 10 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale');
    var svgDotRadius = svgDotSize / 2.0;
  
    return (
      <>
        <div className="home-main">
          <div className="home-panel-1" onClick={() => { this.onPageSelect("history") }}>
            <HomePanelItem heading="History and people" text="The story of the windmill and the people involved with it, from the time it was built to the present day" content={<img className="dw-home-panel-item-content-img" src="/img/home/HistoryAndPeople.jpg" alt="History and People" width="100%" height="100%"></img>}></HomePanelItem>
          </div>
          <div className="home-panel-2" onClick={() => { this.onPageSelect("howItWorks") }}>
            <HomePanelItem heading="How it Works" text="From harnessing wind power to grinding flour: the windmill's machinery explained with an interactive 3D model" content={<img className="dw-home-panel-item-content-img" src="/img/home/HowItWorks.jpg" alt="How it Works" width="100%" height="100%"></img>}></HomePanelItem>
          </div>
          <div className="home-panel-3" onClick={() => { this.onPageSelect("pastIn3D") }}>
            <HomePanelItem heading="The past in 3D" text="Digital reconstructions of the mill site and machinery as it stood in the past, based on our archaeological and historical research" content={<img className="dw-home-panel-item-content-img" src="/img/home/PastIn3D.jpg" alt="Past In 3D" width="100%" height="100%"></img>}></HomePanelItem>
          </div>
          <div className="home-panel-4" onClick={() => { this.onPageSelect("restoration") }}>
            <HomePanelItem heading="Restoration Gallery" text="A photographic record of the restoration of the windmill from 2016 to 2022" content={<img className="dw-home-panel-item-content-img" src="/img/home/RestorationGallery.jpg" alt="Restoration Gallery" width="100%" height="100%"></img>}></HomePanelItem>
          </div>
          <div className="home-panel-5" onClick={() => { this.onPageSelect("windPowerToday") }}>
            <HomePanelItem heading="Wind Energy Today" text="Live data from the National Grid, showing how wind power is contributing to our present day energy needs" content={<img className="dw-home-panel-item-content-img" src="/img/home/WindPowerToday2.jpg" alt="Wind Turbine" width="100%" height="100%"></img>}></HomePanelItem>
          </div>
          <div className="home-panel-5-overlay" onClick={() => { this.onPageSelect("windPowerToday") }}>
          <div className="home-panel-5-overlay-row-upper">
              <div className="home-panel-5-overlay-stat">
                {Math.round(this.props.energyBalanceSummary.windGenerationMw / this.props.energyBalanceSummary.totalGridDemandMw * 100)}%</div>
              power from wind
            </div>
            <div className="home-panel-5-overlay-row-lower">
              <div className="home-panel-5-overlay-live"><svg width={svgDotSize} height={svgDotSize}><circle cx={svgDotRadius} cy={svgDotRadius} r={svgDotRadius} fill={"#FFFFFF"}></circle></svg>&nbsp;<b>LIVE</b></div>
              UK National Grid
            </div>
          </div>
          <div className="home-panel-6" onClick={() => { this.onPageSelect("smartmolen") }}>
            <HomePanelItem heading="SmartMolen.com" text="Live weather, telemetry and webcams from other windmills participating in this award-winning digital monitoring project" content={<SmartmolenMiniMap />}>
            </HomePanelItem>
          </div>
          <div className="home-panel-telemetry" onClick={() => { this.onTelemetryPanelSelect() }}>
            <HomePageTelemetryPanel upminsterTelemetry={this.props.upminsterTelemetry} />
          </div>
        </div>
      </>
    )
  }
}

export { HomeMainPanel }



