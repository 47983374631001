import React from 'react';
import './WindPowerTodayLeftPanel.css';
import { DigitalWindmillLeftPanelFooter } from '../digitalWindmillLayout/DigitalWindmillLeftPanelFooter';
import { DigitalWindmillLeftPanelHeader } from '../digitalWindmillLayout/DigitalWindmillLeftPanelHeader';

class WindPowerTodayLeftPanel extends React.Component {

  componentDidMount() {
  }


  onPageSelect(page) {
    this.props.onPageSelect(page);
  }

  render() {
    return <>
      <DigitalWindmillLeftPanelHeader title={"Wind Energy Today"} onPageSelect={() => this.onPageSelect("home")} backgroundColor={"#176397"} />

      <div className="dw-left-panel-content">

        <div className="dw-left-panel-text">
          <div className="dw-wind-energy-left-panel-wind-info">
          <div className="dw-wind-energy-left-panel-heading">UK Wind Energy in Numbers</div>(statistics from August 2022)
            <div className="dw-wind-energy-left-panel-stat-row">
              <div className="dw-wind-energy-left-panel-stat-box"><div className="dw-wind-energy-left-panel-stat">11,198</div>turbines</div>
              <div className="dw-wind-energy-left-panel-stat-box"><div className="dw-wind-energy-left-panel-stat">25.5 GW</div>total capacity</div>
              <div className="dw-wind-energy-left-panel-stat-box"><div className="dw-wind-energy-left-panel-stat">24.8%</div>annual UK electricity from wind</div>
            </div>
            The United Kingdom is widely considered to be an excellent location for power generation by wind, and probably the best in Europe.  Power generation from wind surpassed coal in 2016 and nuclear power in 2020 in terms of contribution to the UK National Grid.
          </div>

          <div className="dw-wind-energy-left-panel-balancing-info">
          <div className="dw-wind-energy-left-panel-heading">Reliability and balancing</div>
            <br />There is substantial day-to-day variation in the total power generated from wind, depending on the weather, but the total annual output is reliable and consistent.

            <br /><br />Energy supply to the grid is continually balanced by sophisticated computer systems.
            <br /><br />You could think and discuss:
            <ul>
              <li>Are wind and solar generation levels high or low at the moment and why might that be?</li><br />
              <li>Which countries are we importing and exporting electricity from and why might that be?</li><br />
            </ul>
          </div>

        </div>

      </div>

      <DigitalWindmillLeftPanelFooter onPageSelect={() => this.onPageSelect("home")} />
    </>;
  }
}

export { WindPowerTodayLeftPanel }


