import React from 'react'
import './GroundFloorPhotoCarousel.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import 'swiper/css/effect-fade';

class GroundFloorPhotoCarousel extends React.Component {

    render() {
        return (
            <>
                <div className="ground-floor-carousel-container">
                   <Swiper modules={[Autoplay]} effect='fade' fadeEffect={{crossFade: true}} loop={true} speed={3000}
                        autoplay={{
                            delay: 60000,
                            disableOnInteraction: false
                        }}>
                        <SwiperSlide key="1">
                            <div className="ground-floor-carousel-image-container">
                                <img src={`img/groundFloorPhotos/1.jpg`} alt={'1'}></img>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="2">
                            <div className="ground-floor-carousel-image-container">
                                <img src={`img/groundFloorPhotos/2.jpg`} alt={'2'}></img>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="3">
                            <div className="ground-floor-carousel-image-container">
                                <img src={`img/groundFloorPhotos/3.jpg`} alt={'3'}></img>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="4">
                            <div className="ground-floor-carousel-image-container">
                                <img src={`img/groundFloorPhotos/4.jpg`} alt={'4'}></img>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="5">
                            <div className="ground-floor-carousel-image-container">
                                <img src={`img/groundFloorPhotos/5.jpg`} alt={'5'}></img>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="6">
                            <div className="ground-floor-carousel-image-container">
                                <img src={`img/groundFloorPhotos/6.jpg`} alt={'6'}></img>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="7">
                            <div className="ground-floor-carousel-image-container">
                                <img src={`img/groundFloorPhotos/7.jpg`} alt={'7'}></img>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="8">
                            <div className="ground-floor-carousel-image-container">
                                <img src={`img/groundFloorPhotos/8.jpg`} alt={'8'}></img>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="9">
                            <div className="ground-floor-carousel-image-container">
                                <img src={`img/groundFloorPhotos/9.jpg`} alt={'9'}></img>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="10">
                            <div className="ground-floor-carousel-image-container">
                                <img src={`img/groundFloorPhotos/10.jpg`} alt={'10'}></img>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="11">
                            <div className="ground-floor-carousel-image-container">
                                <img src={`img/groundFloorPhotos/11.jpg`} alt={'11'}></img>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key="12">
                            <div className="ground-floor-carousel-image-container">
                                <img src={`img/groundFloorPhotos/12.jpg`} alt={'12'}></img>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </>
        )
    }
}

export { GroundFloorPhotoCarousel }
