import React from 'react'
import './BringItToLifePage.css';

class BringItToLifePage extends React.Component {

  render() {
    // TODO: using window.location.href is a bit of a hack, but
    // it does work
    const videoId = window.location.href.split('/')[4]

    return (
      <>
        <div className="bring-it-to-life-video">
          {videoId === "1" &&
            <iframe src="https://player.vimeo.com/video/828534230?h=0d1b020610&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=0&amp;autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Bring It To Life 1 - Jog Scry"></iframe>
          }
          {videoId === "2" &&
            <iframe src="https://player.vimeo.com/video/828143458?h=e934e82156&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=0&amp;autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Bring It To Life 1 - Jog Scry"></iframe>
          }
          {videoId === "3" &&
            <iframe src="https://player.vimeo.com/video/828668923?h=d9531b95c9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=0&amp;autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Bring It To Life 1 - Jog Scry"></iframe>
          }
          {videoId === "4" &&
            <iframe src="https://player.vimeo.com/video/828533279?h=18bf0245b7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=0&amp;autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Bring It To Life 1 - Jog Scry"></iframe>
          }
          {videoId === "5" &&
            <iframe src="https://player.vimeo.com/video/828533236?h=f20f6fa347&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=0&amp;autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Bring It To Life 1 - Jog Scry"></iframe>
          }
          {videoId === "6" &&
            <iframe src="https://player.vimeo.com/video/850722401?h=3166bc417a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=0&amp;autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Bring It To Life 1 - Jog Scry"></iframe>
          }
          {videoId === "7" &&
            <iframe src="https://player.vimeo.com/video/827765711?h=3d65315aa8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=0&amp;autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Bring It To Life 1 - Jog Scry"></iframe>
          }
          {videoId === "8" &&
            <iframe src="https://player.vimeo.com/video/850725595?h=388319b217&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=0&amp;autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Bring It To Life 1 - Jog Scry"></iframe>
          }
        </div>

      </>
    )
  }
}

export { BringItToLifePage }
