import React from 'react'
import './GroundFloorScreenSafetyVideo.css'

class GroundFloorScreenSafetyVideo extends React.Component {

  componentDidMount() {
    this.timeout = setTimeout(() => this.goHome(), 67000);
  }

  goHome() {
    console.log("Going home ...");
    if (this.timeout) {
      clearTimeout(this.timeout);
      console.log("Cleared goHome timeout");
    }
    this.props.onReturnToHomeClick();
  }

  render() {
    return (
      <>
        <div className="ground-floor-safety-video">
          <iframe id="safety-video-iframe" src="https://player.vimeo.com/video/827769508?h=fc42ea5093&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=0&amp;autoplay=1" width="100%" height="100%" frameBorder="0" allow="autoplay" allowFullScreen title="Bring It To Life 1 - Jog Scry"></iframe>
          <script src="https://player.vimeo.com/api/player.js"></script>

          <script>
            var iframe = $('#safety-video-iframe');
            var player = new Vimeo.Player(iframe);

            player.play();

            player.on('finish', function() {
              console.log("Finished (TODO: event doesn't work!)")
            });
          </script>

          <div className="ground-floor-safety-video-stop-button" onClick={() => { this.goHome() }}>
            Stop
          </div>
        </div>
      </>
    )
  }
}

export { GroundFloorScreenSafetyVideo }



