import React from 'react';
import './PastIn3DLeftPanel.css';
import { DigitalWindmillLeftPanelFooter } from '../digitalWindmillLayout/DigitalWindmillLeftPanelFooter';
import { DigitalWindmillLeftPanelHeader } from '../digitalWindmillLayout/DigitalWindmillLeftPanelHeader';

class PastIn3DLeftPanel extends React.Component {

  componentDidMount() {
    window.plausible('pageview', { u: "https://digital.upminsterwindmill.org/pastIn3d/" + this.props.selectedModel.headline });
  }

  onModelSelect(model) {
    this.props.onModelSelect(model);
    window.plausible('pageview', { u: "https://digital.upminsterwindmill.org/pastIn3d/" + this.props.selectedModel.headline });
  }

  onPageSelect(page) {
    this.props.onPageSelect(page);
  }

  // TODO: make category recognition dynamic and order correctly between and within categories
  render() {
    return <>
      <DigitalWindmillLeftPanelHeader title={"The Past in 3D"} onPageSelect={() => this.onPageSelect("home")} backgroundColor={"#46402b"} />

      <div className="dw-left-panel-content-dark" style={{}}>
        <div className="dw-left-panel-text-dark">This collection of 3D models, by Cliff Featherston and Jonathan Green, reconstruct parts of the site and the associated machinery based on archaeological and historical research.</div>

        <div className="dw-pastin3d-category-heading">Archaeology</div>
        {this.props.modelList.models.filter(e => (e.category === "Archaeology")).map((model) =>
          <>
            <div className="dw-pastin3d-entry" onClick={() => { this.onModelSelect(model) }} style={{ backgroundColor: (model === this.props.selectedModel) ? "#092f4f" : "#282828" }}>
              <div className="dw-pastin3d-entry-name">{model.headline}</div>
            </div>
          </>
        )}

        <div className="dw-pastin3d-category-heading">Milling machinery</div>
        {this.props.modelList.models.filter(e => (e.category === "Milling machinery")).map((model) =>
          <>
            <div className="dw-pastin3d-entry" onClick={() => { this.onModelSelect(model) }} style={{ backgroundColor: (model === this.props.selectedModel) ? "#092f4f" : "#282828" }}>
              <div className="dw-pastin3d-entry-name">{model.headline}</div>
            </div>
          </>
        )}

        <div className="dw-pastin3d-category-heading">Mill structure</div>
        {this.props.modelList.models.filter(e => (e.category === "Mill structure")).map((model) =>
          <>
            <div className="dw-pastin3d-entry" onClick={() => { this.onModelSelect(model) }} style={{ backgroundColor: (model === this.props.selectedModel) ? "#092f4f" : "#282828" }}>
              <div className="dw-pastin3d-entry-name">{model.headline}</div>
            </div>
          </>
        )}

        <div className="dw-pastin3d-category-heading">Miscellaneous</div>
        {this.props.modelList.models.filter(e => (e.category === "Miscellaneous")).map((model) =>
          <>
            <div className="dw-pastin3d-entry" onClick={() => { this.onModelSelect(model) }} style={{ backgroundColor: (model === this.props.selectedModel) ? "#092f4f" : "#282828" }}>
              <div className="dw-pastin3d-entry-name">{model.headline}</div>
            </div>
          </>
        )}

      </div>

      <DigitalWindmillLeftPanelFooter onPageSelect={() => this.onPageSelect("home")} />
    </>;
  }
}

export { PastIn3DLeftPanel }


