import React from 'react';
import { DigitalWindmillLeftPanelFooter } from '../digitalWindmillLayout/DigitalWindmillLeftPanelFooter';
import { DigitalWindmillLeftPanelHeader } from '../digitalWindmillLayout/DigitalWindmillLeftPanelHeader';
import ConsoleTableItem from './consoleTableItem';
import MonitoringStatusSummary from './monitoringStatusSummary';

class SmartmolenLeftPanel extends React.Component {

  componentDidMount() {
    window.plausible('pageview', { u: "https://digital.upminsterwindmill.org/smartmolen/" });
  }

  onPageSelect(page) {
    this.props.onPageSelect(page);
  }

  selectMolen(molen) {
    this.props.onSelectMolen(molen);
  }


  render() {
    return <>
      <DigitalWindmillLeftPanelHeader title={"smartmolen.com"} onPageSelect={() => this.onPageSelect("home")} backgroundColor={"#0f4c81"} />

      <div className="dw-left-panel-content">

        <MonitoringStatusSummary molenList={this.props.molenList} />

        <div className="dw-left-panel-sub-content">
          {this.props.molenList.map((molen, index) => (
            <div onClick={() => { this.selectMolen(molen) }}>
              <ConsoleTableItem key={molen.molenId} molen={molen} selectedSmartmolen={this.props.selectedSmartmolen} />
            </div>
          ))}
        </div>



      </div>

      <DigitalWindmillLeftPanelFooter onPageSelect={() => this.onPageSelect("home")} />
    </>;
  }
}

export { SmartmolenLeftPanel }


