import React from 'react'
import './SmartmolenMiniMap.css'

class SmartmolenMiniMap extends React.Component {

  componentDidMount() {
  }


  render() {
    return (
      <>
        <div className="smartmolen-mini-map-wrap">
          <iframe src="https://www.smartmolen.com/map/map.html?zoom=6&amp;lat=51.8&amp;lon=-1.1&amp;referrer=dw" title="SmartMolen Mini Map" name="smartmolen-mini-map-frame" width="100%" height="100%" frameBorder="0"> </iframe>
        </div>
      </>
    )
  }
}

export { SmartmolenMiniMap }



