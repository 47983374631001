import React from 'react';
import './SmartmolenMainPanel.css';
import QRCode from 'react-qr-code';
import Clock from 'react-live-clock';

class SmartmolenMainPanel extends React.Component {

  componentDidMount() {
    window.addEventListener("message", (e) => {
      this.props.onSmartmolenViewSelect(e.data);
    });
  }

  render() {

    console.info("Mode: " + this.props.mode);

    var screenSizeCompensationScale = parseFloat(getComputedStyle(document.body).getPropertyValue('--iframe-scale')) * 100;

    var mapUrl = "https://www.smartmolen.com/map/map.html?zoom=6&lat=54.2&lon=1.0&referrer=dw&screenZoom=" + screenSizeCompensationScale;

    if (this.props.selectedSmartmolen !== null) {
      mapUrl = "https://www.smartmolen.com/map/map.html?zoom=9&lat=" + this.props.selectedSmartmolen.location.latitude + "&lon=" + this.props.selectedSmartmolen.location.longitude + "&referrer=dw&molenFocus=" + this.props.selectedSmartmolen.shortName + "&screenZoom=" + screenSizeCompensationScale;
    }

    return (
      <>
        {(this.props.mode === "map" || this.props.mode === "map_popup") && <>
          <div className="smartmolen-map">
            <iframe src={mapUrl} title="Smartmolen Map" name="smartmolen-map-frame" width="100%" height="100%" frameBorder="0"> </iframe>
          </div>

          <div className="windy-link-block">
          </div>

          <div className="windy-menu-block">
          </div>

          <div className="console-live-panel">
            <div className="console-live-panel-top">
              <svg height="20" width="20">
                <circle cx="10" cy="10" r="8" stroke="#ffffff" strokeWidth="0" fillOpacity="1" fill='#ffffff'>
                  <animate
                    attributeName="fill"
                    values="#ffffff;transparent"
                    begin="0s"
                    dur="2s"
                    calcMode="discrete"
                    repeatCount="indefinite"
                  />
                </circle>
              </svg>
              <div className="console-live-title"><b>LIVE</b></div>
            </div>
            <div className="console-live-panel-clock">
              <Clock format={'D MMMM YYYY HH:mm:ss'}
                ticking={true}></Clock>
            </div>
            <div className="console-live-panel-bottom">
              Scan the QR code to view live telemetry and<br/>windmill webcams on your own device
            </div>
            <div className="console-live-panel-qr-code" style={{ background: 'white', padding: '6px' }}>
              <QRCode size={90 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale')} value="https://www.smartmolen.com"></QRCode>
            </div>
          </div>
        </>
        }

        {(this.props.mode === "mill" && this.props.selectedSmartmolen != null) && <>
          <div className="smartmolen-content">
            <iframe src={"https://www.smartmolen.com/dw1/" + this.props.selectedSmartmolen.shortName + "?fixedSizeItemScale=" + document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale')} title="SmartMolen Mill Info" name="smartmolen-mill-frame" width="100%" height="100%" frameBorder="0"> </iframe>
          </div>
        </>
        }

        {(this.props.mode === "live" && this.props.selectedSmartmolen != null) && <>
          <div className="smartmolen-content">
            <iframe src={"https://www.smartmolen.com/dw2/" + this.props.selectedSmartmolen.shortName + "?fixedSizeItemScale=" + document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale')} title="SmartMolen Mill Live" name="smartmolen-mill-frame" width="100%" height="100%" frameBorder="0"> </iframe>
          </div>
        </>
        }

        {(this.props.mode === "windrose" && this.props.selectedSmartmolen != null) && <>
          <div className="smartmolen-content">
            <iframe src={"https://www.smartmolen.com/dw3/" + this.props.selectedSmartmolen.shortName + "?fixedSizeItemScale=" + document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale')} title="SmartMolen Mill Wind Rose" name="smartmolen-mill-frame" width="100%" height="100%" frameBorder="0"> </iframe>
          </div>
        </>
        }

        {(this.props.mode === "tracker" && this.props.selectedSmartmolen != null) && <>
          <div className="smartmolen-content">
            <iframe src={"https://www.smartmolen.com/dw4/" + this.props.selectedSmartmolen.shortName + "?fixedSizeItemScale=" + document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale')} title="SmartMolen Mill Tracker" name="smartmolen-mill-frame" width="100%" height="100%" frameBorder="0"> </iframe>
          </div>
        </>
        }


      </>
    )
  }
}

export { SmartmolenMainPanel }
