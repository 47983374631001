import React from 'react'
import './DigitalWindmillLayout.css';
import { HomeLeftPanel } from '../home/HomeLeftPanel';
import { HomeMainPanel } from '../home/HomeMainPanel';
import { HistoryLeftPanel } from '../history/HistoryLeftPanel';
import { HistoryMainPanel } from '../history/HistoryMainPanel';
import { HowItWorksMainPanel } from '../howItWorks/HowItWorksMainPanel';
import { HowItWorksLeftPanel } from '../howItWorks/HowItWorksLeftPanel';
import { SmartmolenLeftPanel } from '../smartmolen/SmartmolenLeftPanel';
import { SmartmolenMainPanel } from '../smartmolen/SmartmolenMainPanel';
import { PastIn3DLeftPanel } from '../pastIn3D/PastIn3DLeftPanel';
import { PastIn3DMainPanel } from '../pastIn3D/PastIn3DMainPanel';
import { WindPowerTodayLeftPanel } from '../windPowerToday/WindPowerTodayLeftPanel';
import { WindPowerTodayMainPanel } from '../windPowerToday/WindPowerTodayMainPanel';

import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';


class DigitalWindmillLayout extends React.Component {

  // FUTURE - it's quite messy passing all messages between left and right panels in this location,
  // but makes the app appear better as it avoids a full screen redraw as you navigate.
  // Alternative designs could decouple the various components from the logic in this class but in practice
  // it's workable at current level of complexity

  state = {
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: "home",
      selectedHistoryTimelineItem: null,
      selectedRestorationTimelineItem: null,
      selectedPastIn3DModel: null,
      selectedSmartmolen: null,
      smartmolenViewMode: "map"
    }
    this.onHistoryTimelineClick = this.onHistoryTimelineClick.bind(this);
    this.onRestorationTimelineClick = this.onRestorationTimelineClick.bind(this);
    this.onPastIn3DModelSelect = this.onPastIn3DModelSelect.bind(this);
    this.onPageSelect = this.onPageSelect.bind(this);
    this.onSmartmolenLeftNavSelect = this.onSmartmolenLeftNavSelect.bind(this);
    this.onSmartmolenMainPanelPageSelect = this.onSmartmolenMainPanelPageSelect.bind(this);
    this.onTelemetryPanelSelect = this.onTelemetryPanelSelect.bind(this);
  }

  // used to switch photographic gallery views back to first photo
  resetCount = 0;

  componentDidMount() {

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`https://` + process.env.REACT_APP_SMARTMOLEN_API)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    console.log(hubConnection);

    this.setState({ hubConnection }, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          console.log('Connection started!');
        })
        .catch(err => {
          console.log('Error while establishing connection :(');
          console.log(err);
        });


      this.state.hubConnection.on('molenUpdated',
        (receivedMessage) => {
          this.reloadSmartmolenData();
        });

    });

    this.reloadSmartmolenData();
    this.fetchHistoryTimeline();
    this.fetchRestorationTimeline();
    this.fetchPastIn3DModelList();
  }

  reloadSmartmolenData() {

    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molen/upminster`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          upminsterTelemetry: response,
        })
      });

    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molenList`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          molenList: response,
        })
      });

  }

  onHistoryTimelineClick(entry) {
    this.resetCount++;
    this.setState({ selectedHistoryTimelineItem: entry, resetToFirstFrameCount: this.resetCount });
  }

  onRestorationTimelineClick(entry) {
    this.resetCount++;
    this.setState({ selectedRestorationTimelineItem: entry, resetToFirstFrameCount: this.resetCount });
  }

  onPastIn3DModelSelect(entry) {
    this.setState({ selectedPastIn3DModel: entry, resetToFirstFrameCount: this.resetCount });
  }

  onSmartmolenLeftNavSelect(entry) {
    this.setState({ selectedSmartmolen: entry, resetToFirstFrameCount: this.resetCount });
  }

  onSmartmolenMainPanelPageSelect(entry) {
    try {
      var smartmolenSelectionInstruction = JSON.parse(entry);

      if (typeof (smartmolenSelectionInstruction.selectedMolen) !== 'undefined') {
        fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molen/` + smartmolenSelectionInstruction.selectedMolen)
          .then(response => response.json())
          .then(response => {
            this.setState({ selectedSmartmolen: response, smartmolenViewMode: smartmolenSelectionInstruction.mode });
          });
      }
      else if (smartmolenSelectionInstruction.mode !== null) {
        this.setState({ smartmolenViewMode: smartmolenSelectionInstruction.mode });
      }
    }
    catch (e) {
      // do nothing - miscellaneous messages may be picked up which will fail JSON parse
    }
  }

  onTelemetryPanelSelect()
  {
    this.setSmartmolenMode("upminster", "live");
    this.setState({ currentPage: "smartmolen" });
  }


  setSmartmolenMode(shortName, mode) {
    if (shortName !== null) {
      fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molen/` + shortName)
        .then(response => response.json())
        .then(response => {
          this.setState({ selectedSmartmolen: response, smartmolenViewMode: mode });
        });
    }
    else {
      this.setState({ selectedSmartmolen: null, smartmolenViewMode: "map" });
    }
  }

  onPageSelect(page) {
    console.info("Page Selected: " + page);
    // TODO: gracefully handle empty lists
    if (page === "history") { this.setState({ selectedHistoryTimelineItem: this.state.historyTimeline.entries[0] }) }
    if (page === "restoration") { this.setState({ selectedRestorationTimelineItem: this.state.restorationTimeline.entries[0] }) }
    if (page === "pastIn3D") { this.setState({ selectedPastIn3DModel: this.state.pastIn3DModelList.models[0] }) }
    if (page === "smartmolen") { this.setState({ selectedSmartmolen: null, smartmolenViewMode: "map" }) }
    this.setState({ currentPage: page })
  }

  fetchHistoryTimeline() {
    fetch(`https://` + process.env.REACT_APP_DIGITAL_WINDMILL_API + `/api/timeline/` + this.props.config.historicalTimelineVersion)
      .then(response => response.json())
      .then(response => {
        this.setState({ historyTimeline: response })
        if (response.entries.length > 0) {
          this.setState({ selectedHistoryTimelineItem: response.entries[0] })
        }
      });
  }

  fetchRestorationTimeline() {
    fetch(`https://` + process.env.REACT_APP_DIGITAL_WINDMILL_API + `/api/timeline/` + this.props.config.restorationTimelineVersion)
      .then(response => response.json())
      .then(response => {
        this.setState({ restorationTimeline: response })
        if (response.entries.length > 0) {
          this.setState({ selectedRestorationTimelineItem: response.entries[0] })
        }
      });
  }

  fetchPastIn3DModelList() {
    fetch(`https://` + process.env.REACT_APP_DIGITAL_WINDMILL_API + `/api/sketchFabModelList/` + this.props.config.pastIn3dModelListVersion)
      .then(response => response.json())
      .then(response => {
        this.setState({ pastIn3DModelList: response })
        if (response.models.length > 0) {
          this.setState({ selectedPastIn3DModel: response.models[0] })
        }
      });
  }



  render() {
    return (
      <>

        {(this.state.selectedRestorationTimelineItem !== null) &&
          <link rel="preload" as="image" href={`https://` + process.env.REACT_APP_DIGITAL_WINDMILL_API + `/api/image/` + this.state.selectedRestorationTimelineItem.images[0].resourceId} />
        }

        {(this.state.selectedHistoryTimelineItem !== null) &&
          <link rel="preload" as="image" href={`https://` + process.env.REACT_APP_DIGITAL_WINDMILL_API + `/api/image/` + this.state.selectedHistoryTimelineItem.images[0].resourceId} />
        }


        <div className="home-background">
          <div className="home-left-panel">
            {this.state.currentPage === "home" && <HomeLeftPanel />}
            {this.state.currentPage === "history" && <HistoryLeftPanel title="History and People" onTimelineClick={this.onHistoryTimelineClick} onPageSelect={this.onPageSelect} timeline={this.state.historyTimeline} selectedTimelineItem={this.state.selectedHistoryTimelineItem} />}
            {this.state.currentPage === "howItWorks" && <HowItWorksLeftPanel onPageSelect={this.onPageSelect} />}
            {this.state.currentPage === "pastIn3D" && <PastIn3DLeftPanel onModelSelect={this.onPastIn3DModelSelect} onPageSelect={this.onPageSelect} modelList={this.state.pastIn3DModelList} selectedModel={this.state.selectedPastIn3DModel} />}
            {this.state.currentPage === "restoration" && <HistoryLeftPanel title="Restoration Gallery" onTimelineClick={this.onRestorationTimelineClick} onPageSelect={this.onPageSelect} timeline={this.state.restorationTimeline} selectedTimelineItem={this.state.selectedRestorationTimelineItem} />}
            {this.state.currentPage === "smartmolen" && <SmartmolenLeftPanel onPageSelect={this.onPageSelect} molenList={this.state.molenList} onSelectMolen={this.onSmartmolenLeftNavSelect} selectedSmartmolen={this.state.selectedSmartmolen} />}
            {this.state.currentPage === "windPowerToday" && <WindPowerTodayLeftPanel onPageSelect={this.onPageSelect}  />}
          </div>

          <div className="home-main-panel">
            {this.state.currentPage === "home" && <HomeMainPanel onPageSelect={this.onPageSelect} upminsterTelemetry={this.state.upminsterTelemetry} onTelemetryPanelSelect={this.onTelemetryPanelSelect} energyBalanceSummary={this.props.config.energyBalanceSummary} />}
            {this.state.currentPage === "history" && <HistoryMainPanel selectedTimelineItem={this.state.selectedHistoryTimelineItem} resetToFirstFrameCount={this.state.resetToFirstFrameCount} />}
            {this.state.currentPage === "howItWorks" && <HowItWorksMainPanel />}
            {this.state.currentPage === "pastIn3D" && <PastIn3DMainPanel selectedModel={this.state.selectedPastIn3DModel} />}
            {this.state.currentPage === "restoration" && <HistoryMainPanel selectedTimelineItem={this.state.selectedRestorationTimelineItem} resetToFirstFrameCount={this.state.resetToFirstFrameCount} />}
            {this.state.currentPage === "smartmolen" && <SmartmolenMainPanel molenList={this.state.molenList} selectedSmartmolen={this.state.selectedSmartmolen} mode={this.state.smartmolenViewMode} onSmartmolenViewSelect={this.onSmartmolenMainPanelPageSelect} />}
            {this.state.currentPage === "windPowerToday" && <WindPowerTodayMainPanel energyBalanceSummary={this.props.config.energyBalanceSummary} />}
          </div>
        </div>
      </>
    )
  }
}

export { DigitalWindmillLayout }
