import React from 'react';
import { DigitalWindmillLeftPanelFooter } from '../digitalWindmillLayout/DigitalWindmillLeftPanelFooter';
import { DigitalWindmillLeftPanelHeader } from '../digitalWindmillLayout/DigitalWindmillLeftPanelHeader';

class HowItWorksLeftPanel extends React.Component {

  componentDidMount() {
    window.plausible('pageview', { u: "https://digital.upminsterwindmill.org/howItWorks/" });
  }

  onPageSelect(page)
  {
    this.props.onPageSelect(page);
  }

  render() {
    return <>
      <DigitalWindmillLeftPanelHeader title={"How it works"} onPageSelect={() => this.onPageSelect("home")}  backgroundColor={"#46402b"} />

      <div className="dw-left-panel-content">

      <div className="dw-left-panel-text">This 3D model, made by Cliff Featherstone, shows the structure of the mill and its principal machinery.</div>

      <div className="dw-left-panel-text">In due course we are planning to make further enhancements to this model to make it animated, more interactive and easier for everyone to navigate.<br /><br />In the meantime you can explore the model by following the instructions below.</div>

      <div className="dw-left-panel-instruction" style={{ "background-color": "#46402b" }}>
          <img src="/img/touchiconwhite.png" height={72 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale') } alt="Touch"></img>
          <div className="dw-left-panel-instruction-text">
            <b>Touch and drag</b> to turn and move the model<br /><br />
            <b>Touch with two fingers</b> to zoom in or out<br /><br />
            <b>Touch the annotation numbers</b> to view more information about the component parts<br />
          </div>
        </div>
      </div>


      <DigitalWindmillLeftPanelFooter onPageSelect={() => this.onPageSelect("home")} />
    </>;
  }
}

export { HowItWorksLeftPanel }


