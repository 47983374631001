import React from 'react'
import './DigitalWindmillLayout.css';

class DigitalWindmillLeftPanelFooter extends React.Component {

  componentDidMount() {
  }

  goHome() {
    this.props.onPageSelect("home");
  }

  render() {
    return (
      <>
        <div className="dw-left-panel-footer" onClick={() => {this.goHome()}}>
          Return to home screen
        </div>
      </>
    )
  }
}

export { DigitalWindmillLeftPanelFooter }



