import React from 'react'
import './HistoryLeftPanel.css';
import { DigitalWindmillLeftPanelHeader } from '../digitalWindmillLayout/DigitalWindmillLeftPanelHeader';
import { DigitalWindmillLeftPanelFooter } from '../digitalWindmillLayout/DigitalWindmillLeftPanelFooter';


class HistoryLeftPanel extends React.Component {

  componentDidMount() {
    window.plausible('pageview', { u: "https://digital.upminsterwindmill.org/timeline/" + this.props.timeline.timelineCategory + "/" + this.props.selectedTimelineItem.sequence });
  }

  onTimelineEntryClick(entry) {
    this.props.onTimelineClick(entry);
    window.plausible('pageview', { u: "https://digital.upminsterwindmill.org/timeline/" + this.props.timeline.timelineCategory + "/" + this.props.selectedTimelineItem.sequence });
  }

  onPageSelect(page)
  {
    this.props.onPageSelect(page);
  }

  render() {
    return <>
      <DigitalWindmillLeftPanelHeader title={this.props.title} onPageSelect={() => this.onPageSelect("home")} backgroundColor={"#46402b"} />

      <div className="dw-left-panel-content">
        {this.props.timeline.entries.map((entry) =>
          <>
            <div className="dw-timeline-entry" onClick={() => { this.onTimelineEntryClick(entry)}} style={{backgroundColor: (entry === this.props.selectedTimelineItem) ? "#092f4f" : "#282828" }}>
              <div className="dw-timeline-entry-header">
                <div className="dw-timeline-entry-date">{entry.timelineDate}&nbsp;&nbsp;</div><div className="dw-timeline-entry-headline">{entry.headline}</div>
              </div>
              <div className="dw-timeline-entry-text">{entry.text}</div>
            </div>
            {(entry.images !== null && entry.images.length > 0) && <link rel="preload" as="image" href={`https://` + process.env.REACT_APP_DIGITAL_WINDMILL_API + `/api/image/` + entry.images[0].resourceId} />}
            {(entry.images !== null && entry.images.length > 1) && <link rel="preload" as="image" href={`https://` + process.env.REACT_APP_DIGITAL_WINDMILL_API + `/api/image/` + entry.images[1].resourceId} />}
            {(entry.images !== null && entry.images.length > 2) && <link rel="preload" as="image" href={`https://` + process.env.REACT_APP_DIGITAL_WINDMILL_API + `/api/image/` + entry.images[2].resourceId} />}
          </>
        )}
      </div>

      <DigitalWindmillLeftPanelFooter onPageSelect={() => this.onPageSelect("home")} />
    </>;

  }
}

export { HistoryLeftPanel }


