import React from 'react';
import WindLogic from './windLogic';
import './monitoringStatusSummary.css';

class MonitoringStatusSummary extends React.Component {

    componentDidMount() {
    }

    render() {

        // identify the highest severity alert open
        var maxWindWarningSeverity = 0;
        function findMaxSeverityWindWarning(windWarning)
        {
            if (windWarning.tailWindWarningLevel > maxWindWarningSeverity) {maxWindWarningSeverity = windWarning.tailWindWarningLevel;}
            if (windWarning.strongWindWarningLevel > maxWindWarningSeverity) {maxWindWarningSeverity = windWarning.strongWindWarningLevel;}
        }
        function findMaxSeverityWindWarningForMolen(molen)
        {
            if (molen.windWarnings != null) {molen.windWarnings.forEach(findMaxSeverityWindWarning)};
        }
        this.props.molenList.forEach(findMaxSeverityWindWarningForMolen);

        // identify current alerts
        function hasOpenWindWarning(molen) {
            return (molen.windWarnings != null) && (molen.windWarnings.length > 0);
        }
        var molensWithWindWarnings = this.props.molenList.filter(hasOpenWindWarning);
        var molensWithWindWarningsList = "";
        if (molensWithWindWarnings.length === 1) { molensWithWindWarningsList = molensWithWindWarnings[0].name; }
        if (molensWithWindWarnings.length === 2) {molensWithWindWarningsList = molensWithWindWarnings[0].name + " & " + molensWithWindWarnings[1].name;}
        if (molensWithWindWarnings.length >= 3) {molensWithWindWarningsList = molensWithWindWarnings.length + " windmills";}

        return(
            <>
                <div className="monitoring-status-panel" style={{ backgroundColor: new WindLogic().getWindIndicationColourDarker(maxWindWarningSeverity) }}>
                Live monitoring <b>{this.props.molenList.length}</b> windmills<br />
                {(molensWithWindWarnings.length === 0) && "No wind warnings"}
                {(molensWithWindWarnings.length === 1) && <>Wind Warning at {molensWithWindWarningsList}<br /></>}
                {(molensWithWindWarnings.length > 1) && <>Wind Warnings at {molensWithWindWarningsList}<br /></>}
                </div>
            </>
        );
    }
}



export default MonitoringStatusSummary