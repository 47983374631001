import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination } from "swiper";
import { Navigation } from "swiper";
import './HistoryMainPanel.css';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


class HistoryMainPanel extends React.Component {

  constructor(props) {
    super(props);
    this.swiperRef = React.createRef();
  }

  lastResetToFirstFrameCount = 0;

  componentDidMount() {
  }

  render() {
    if (this.swiperRef != null && this.swiperRef.current?.swiper != null && this.props.resetToFirstFrameCount != null && (this.props.resetToFirstFrameCount > this.lastResetToFirstFrameCount)) {
      this.swiperRef.current?.swiper.slideTo(0, 0, false);
      this.lastResetToFirstFrameCount = this.props.resetToFirstFrameCount;
    }

    if (this.props.selectedTimelineItem == null || this.props.selectedTimelineItem.images == null || this.props.selectedTimelineItem.images.length === 0) {
      return "No images available";
    }
    else {
      return this.renderLoaded();
    }
  }



  renderLoaded() {
    return (
      <>
        <div className="dw-swiper-container">
          <Swiper
            pagination={{
              clickable: true,
            }}
            navigation={true} modules={[Pagination, Navigation]} className="dw-swiper" ref={this.swiperRef}>
            {this.props.selectedTimelineItem.images.map((image) =>
              <>
                <SwiperSlide key={image.resourceId}>
                  <div className="dw-swiper-content">
                    <div className="dw-swiper-item-image-container"><img src={`https://` + process.env.REACT_APP_DIGITAL_WINDMILL_API + `/api/image/` + image.resourceId} alt={image.caption}></img></div>
                    <div className="dw-swiper-item-caption">{image.caption}</div>
                  </div>
                </SwiperSlide>
              </>
            )}
          </Swiper>
        </div>
      </>
    )
  }
}

export { HistoryMainPanel }



