import React from 'react'
import './HomePageTelemetryPanel.css'
import Clock from 'react-live-clock';
import FantailIcon from '../common/sensorIcons/fantailIcon';
import WindDirectionArrow from '../common/windDirectionArrow';
import SailSpeedSensorIcon from '../common/sensorIcons/sailSpeedSensorIcon';


class HomePageTelemetryPanel extends React.Component {

  render() {
    if (this.props.upminsterTelemetry != null) {
      return this.renderLoaded();
    }
    else {
      return <>...</>;
    }
  }

  renderLoaded() {

    var svgDotSize = 10 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale');
    var svgDotRadius = svgDotSize / 2.0;

    var noWind = this.props.upminsterTelemetry.latestWeatherObservation.windDirection === null;

    return (
      <>

        <div className="dw-home-panel-telemetry-wrapper">

          <div className="dw-home-panel-telemetry-group-time">
            <div className="dw-home-panel-telemetry-group-heading">&nbsp;</div>
            <div className="dw-telemetry-item-headline-stat"><Clock format={'HH:mm:ss'} ticking={true} /></div>
            <div className="dw-home-panel-telemetry-group-heading"><Clock format={'dddd D MMMM'} ticking={true} /></div>
          </div>


          <div className="dw-home-panel-telemetry-group-weather-station">
            <div className="dw-home-panel-telemetry-group-heading">
              <div className="dw-home-panel-telemetry-live"><svg width={svgDotSize} height={svgDotSize}><circle cx={svgDotRadius} cy={svgDotRadius} r={svgDotRadius} fill={"#FFFFFF"}></circle></svg>&nbsp;LIVE</div>
              WEATHER STATION
            </div>
            <div className="dw-home-panel-telemetry-group-data">
              <div className="dw-telemetry-temp">
                <div className="dw-telemetry-item-heading">Temperature</div>
                <div className="dw-telemetry-item-headline-stat">{Math.round(this.props.upminsterTelemetry.latestWeatherObservation.tempDegC)}&deg;</div>
              </div>
              <div className="dw-telemetry-wind">
                <div className="dw-telemetry-item-heading">Wind</div>
                <div className="dw-telemetry-item-main">
                  <div className="dw-telemetry-item-headline-stat">{(this.props.upminsterTelemetry.latestWeatherObservation.windDirectionCompassPoint === null) ? "None" : this.props.upminsterTelemetry.latestWeatherObservation.windDirectionCompassPoint}</div>
                </div>
                <div className="dw-telemetry-item-lower"> {Math.round(this.props.upminsterTelemetry.latestWeatherObservation.windSpeedMs * 2.237)}&nbsp;mph</div>
              </div>
              <div className="dw-telemetry-wind-arrow">
                {(!noWind) && <WindDirectionArrow windDirection={this.props.upminsterTelemetry.latestWeatherObservation.windDirection} molenOrientation={this.props.upminsterTelemetry.latestOrientationSensorReading.bearing} windSpeedMs={this.props.upminsterTelemetry.latestWeatherObservation.windSpeedMs} size={72 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale')} palette="light" />}
              </div>
            </div>
          </div>

          <div className="dw-home-panel-telemetry-group-cap-monitor">
            <div className="dw-home-panel-telemetry-group-heading">
              <div className="dw-home-panel-telemetry-live"><svg width={svgDotSize} height={svgDotSize}><circle cx={svgDotRadius} cy={svgDotRadius} r={svgDotRadius} fill={"#FFFFFF"}></circle></svg>&nbsp;LIVE</div>
              WINDMILL OPERATION
            </div>
            <div className="dw-home-panel-telemetry-group-data">

              <div className="dw-telemetry-cap-facing">
                <div className="dw-telemetry-item-heading">Cap facing</div>
                <div className="dw-telemetry-item-main">
                  <div className="dw-telemetry-item-headline-stat">{this.props.upminsterTelemetry.latestOrientationSensorReading.compassPoint}</div>
                </div>
                <div className="dw-telemetry-item-lower">{this.props.upminsterTelemetry.latestOrientationSensorReading.bearing}&deg;</div>
              </div>

              <div className="dw-telemetry-sail-speed">
                <div className="dw-telemetry-item-heading">Sail speed</div>
                <div className="dw-telemetry-item-main-row">
                <div className="dw-telemetry-item-main-row-item-left">
                    <SailSpeedSensorIcon size={64 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale')} color="white" rotateSpeed={this.props.upminsterTelemetry.latestSailRotationReading.currentSpeedRpm} strokeWidth={2.5} />
                  </div>

                  <div className="dw-telemetry-item-main-row-item">

                    <div className="dw-telemetry-item-main">
                      <div className="dw-telemetry-item-headline-stat">{Math.round(this.props.upminsterTelemetry.latestSailRotationReading.currentSpeedRpm * 10) / 10}</div>
                      <div className="dw-telemetry-item-unit">&nbsp;rpm</div>
                    </div>
                    <div className="dw-telemetry-item-lower" style={this.props.upminsterTelemetry.latestSailRotationReading.brakeOn ? {"backgroundColor": "#00B000", "padding": "0.2vw"} : {"backgroundColor": "#e0a000", "padding": "0.2vw"}}>
                      {this.props.upminsterTelemetry.latestSailRotationReading.brakeOn ? "BRAKE ON" : "BRAKE OFF"}</div>
                  </div>
                </div>
              </div>

              <div className="dw-telemetry-fantail-speed">
                <div className="dw-telemetry-item-heading">Fantail rotation</div>
                <div className="dw-telemetry-item-main-row">
                  <div className="dw-telemetry-item-main-row-item-left">
                    <FantailIcon size={64 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale')} color="white" rotateSpeed={this.props.upminsterTelemetry.latestFantailSensorReading.currentSpeedRpm} strokeWidth={3} />
                  </div>
                  <div className="dw-telemetry-item-main-row-item">
                    <div className="dw-telemetry-item-main">
                      <div className="dw-telemetry-item-headline-stat">{Math.round(Math.abs(this.props.upminsterTelemetry.latestFantailSensorReading.currentSpeedRpm) * 10) / 10}</div>
                      <div className="dw-telemetry-item-unit">&nbsp;rpm</div>
                    </div>
                    <div className="dw-telemetry-item-lower">
                      {Math.abs(this.props.upminsterTelemetry.latestFantailSensorReading.currentSpeedRpm > 0) ? <>clockwise cap drive</> : <></>}
                      {Math.abs(this.props.upminsterTelemetry.latestFantailSensorReading.currentSpeedRpm < 0) ? <>anti-clockwise cap drive</> : <></>}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export { HomePageTelemetryPanel }
