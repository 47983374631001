import React from 'react'
import './DigitalWindmillLayout.css';

class DigitalWindmillLeftPanelHeader extends React.Component {

  componentDidMount() {
  }

  goHome() {
    this.props.onPageSelect("home");
  }

  render() {
    return (
      <>
        <div className="dw-left-panel-header" onClick={() => {this.goHome()}} style={{"backgroundColor": this.props.backgroundColor}}>
        <img src="/img/fuwlogo.jpg" height="100%" alt="Friends of Upminster Windmill Logo" />
          <div className="dw-left-panel-header-right" >
          <b>DIGITAL WINDMILL</b>
          {this.props.title}
          </div>
        </div>
      </>
    )
  }
}

export { DigitalWindmillLeftPanelHeader }



