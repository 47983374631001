import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.dark.css';
import './App.css';
import { DigitalWindmillWrapper } from './digitalWindmillLayout/DigitalWindmillWrapper';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import { BringItToLifePage } from './bringittolife/BringItToLifePage';
import { GroundFloorScreenLayout } from './groundfloorhomescreen/GroundFloorScreenLayout';

class App extends React.Component {

  componentDidMount() {
    this.calculateScalingFactors();
  }

  calculateScalingFactors() {
    var browserZoom = 100;
    var screenSizeCompensationFactor = 100;

    if (window.location.search != null) {
      var queryString = window.location.search;
      if (queryString != null) {
        var params = new URLSearchParams(queryString);
        var browserZoomQSValue = params.get('browserZoom');
        if (browserZoomQSValue !== null) {
          browserZoom = parseInt(browserZoomQSValue);
        }

        var screenSizeQsValue = params.get('screenSize');
        if (screenSizeQsValue === '4K') {
          screenSizeCompensationFactor = 133;
        }
      }
    }

    var iFrameScale = 100.0 / browserZoom * (screenSizeCompensationFactor / 100.0);
    var iFrameZoom = browserZoom / (screenSizeCompensationFactor / 100.0);

    document.getElementsByClassName("digital-windmill-app")[0].style.setProperty("--iframe-scale", iFrameScale);
    document.getElementsByClassName("digital-windmill-app")[0].style.setProperty("--iframe-zoom", iFrameZoom.toString() + "%");
    document.getElementsByClassName("digital-windmill-app")[0].style.setProperty("--fixed-size-item-scale", iFrameScale);
  }

  render() {
    return (
      <div className="digital-windmill-app">
        <BrowserRouter>
          <Routes>
            <Route path="/groundFloor" element={<GroundFloorScreenLayout />} />
            <Route path="/bringittolife/:videoId" element={<BringItToLifePage />} />
            <Route path="/touchscreen" element={<DigitalWindmillWrapper />} />
            <Route path="*" element={<DigitalWindmillWrapper />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export { App };
