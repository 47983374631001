import React from 'react'
import './PastIn3DMainPanel.css'

class PastIn3DMainPanel extends React.Component {

  componentDidMount() {
  }

  render() {
    return (
      <>
        <div className="past-in-3d-model">
          <iframe title="3d model" width="100%" height="100%" frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src={"https://sketchfab.com/models/" + this.props.selectedModel.sketchFabModelId + "/embed?annotations_visible=1&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&autostart=1"}> </iframe>
        </div>
        <div className="past-in-3d-model-caption">
          {this.props.selectedModel.text}
        </div>
      </>
    )
  }
}

export { PastIn3DMainPanel }
