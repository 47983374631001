import React from 'react'
import './GroundFloorScreenLayout.css';
import { GroundFloorScreenSafetyVideo } from './GroundFloorScreenSafetyVideo';
import { GroundFloorPhotoCarousel } from './GroundFloorPhotoCarousel';

class GroundFloorScreenLayout extends React.Component {

  state = {
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: "home",
      selectedCamera: 1
    }

    this.onPlaySafetyVideoClick = this.onPlaySafetyVideoClick.bind(this);
    this.onReturnToHomeClick = this.onReturnToHomeClick.bind(this);
    this.onViewLiveCamerasClick = this.onViewLiveCamerasClick.bind(this);
  }

  onPlaySafetyVideoClick(entry) {
    console.log("Layout > Play Safety Video");
    this.setState({ currentPage: "safetyVideo" });
  }

  onReturnToHomeClick() {
    console.log("Layout > Return to Home Click");
    this.setState({ currentPage: "home" });
  }

  onViewLiveCamerasClick() {
    console.log("Layout > View Live Cameras Click");
    window.location=("/?source=groundFloor&browserZoom=150&screenSize=4K");
  }


  render() {
    return (
      <>
        {this.state.currentPage === "home" && <>
          <div className='ground-floor-main-panel'><GroundFloorPhotoCarousel /></div>
          <div className='ground-floor-button-panel'>
            <div className='ground-floor-button-panel-title'>
            <img src="img/fuwlogo.jpg" alt="Windmill logo" width="33%"></img>
            <div className='ground-floor-welcome-text'>
            Welcome to<br /><b>Upminster Windmill</b></div>
            </div>
            <div className='ground-floor-safety-video-button' onClick={() => { this.onPlaySafetyVideoClick() }}>Safety Video</div>
            <div className='ground-floor-live-cameras-button' onClick={() => { this.onViewLiveCamerasClick() }}>Digital Windmill</div>
            <div className='ground-floor-live-cameras-submit-info'>Send your photos to <b>info@upminsterwindmill.org</b><br />to be featured here</div>
          </div>
        </>}

        {this.state.currentPage === "safetyVideo" && <GroundFloorScreenSafetyVideo onReturnToHomeClick={() => { this.onReturnToHomeClick() }} />}

      </>
    )
  }
}

export { GroundFloorScreenLayout }
