import React from 'react'
import './HowItWorksMainPanel.css'

class HowItWorksMainPanel extends React.Component {

  componentDidMount() {
  }


  render() {
    return (
      <>
        <div className="dw-how-it-works-3d-model">
          <iframe title="sketchfab-js-api-wrapper-frame" width="100%" height="100%" frameBorder="0" src="/sketchfabWrapper/sfab.html" />
        </div>
      </>
    )
  }
}

export { HowItWorksMainPanel }



