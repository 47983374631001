import React from 'react'
import './DigitalWindmillLayout.css';
import { DigitalWindmillLayout } from './DigitalWindmillLayout';

class DigitalWindmillWrapper extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      config: null,
    }
  }

  componentDidMount() {
    this.fetchConfig();
  }

  fetchConfig() {
    fetch(`https://` + process.env.REACT_APP_DIGITAL_WINDMILL_API + `/api/config/upminster`)
      .then(response => response.json())
      .then(response => {
        this.setState({ config: response });
      });
  }


  render() {
    if (this.state.config == null) { return <>Loading ...</>; }
    else { return this.renderLoaded(); }
  }

  renderLoaded() {
    return (
      <DigitalWindmillLayout config={this.state.config} />
    )
  }
}

export { DigitalWindmillWrapper }
