import React from 'react'

class HomePanelItem extends React.Component {

  componentDidMount() {
  }


  render() {
    return (
      <>
        <div className="dw-home-panel-item">
          <div className="dw-home-panel-item-heading">{this.props.heading}</div>
          <div className="dw-home-panel-item-content">
            {this.props.content}
            <div className="dw-home-panel-item-content-cover"></div>
          </div>
          <div className="dw-home-panel-item-text">{this.props.text}</div>
        </div>
      </>
    )
  }
}

export { HomePanelItem }



