import React from 'react'
import { EnergyBalanceSVG } from './EnergyBalanceSVG';
import './WindPowerTodayMainPanel.css'

class WindPowerTodayMainPanel extends React.Component {

  state = {
    energyBalanceData: null,
    reloadCount: 0,
    reloadIntervalId: 0
  }

  componentDidMount() {
    window.plausible('pageview', { u: "https://digital.upminsterwindmill.org/windPowerToday/" });
    this.reloadEnergyBalanceData();

    const newIntervalId = setInterval(() => {
      this.reloadEnergyBalanceData();
    }, 300000);

    this.setState(prevState => {
      return {
        ...prevState,
        reloadIntervalId: newIntervalId,
      };
    });
  }

  componentWillUnmount = () => {
    clearInterval(this.state.reloadIntervalId);
  }

  reloadEnergyBalanceData() {

    fetch(`https://` + process.env.REACT_APP_DIGITAL_WINDMILL_API + `/api/energyBalance`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          energyBalanceData: response,
        })
      });
  }

  render() {
    if (this.state.energyBalanceData === null) {return <>Loading...</>;}
    else {return this.renderLoaded();}
  }

  renderLoaded() {
    return (
      <>
      <div className="dw-wind-power-main-panel">
        <EnergyBalanceSVG energyBalanceData={this.state.energyBalanceData}></EnergyBalanceSVG>
        </div>
      </>
    )
  }
}

export { WindPowerTodayMainPanel }

