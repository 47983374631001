import React from 'react'
import './HomeLeftPanel.css'

class HomeLeftPanel extends React.Component {

  constructor(props) {
    super(props);

    this.onReturnToGroundFloorHomeScreenClick = this.onReturnToGroundFloorHomeScreenClick.bind(this);
  }

  onReturnToGroundFloorHomeScreenClick(entry) {
    console.log("Layout > Play Safety Video");
    window.location = "/groundFloor";
  }

  source = "";

  componentDidMount() {
  }

  checkSource() {

    if (window.location.search != null) {
      var queryString = window.location.search;
      if (queryString != null) {
        var params = new URLSearchParams(queryString);
        var sourceQSValue = params.get('source');
        if (sourceQSValue !== null) {
          this.source = sourceQSValue;
        }
      }
    }
  }


  render() {
    this.checkSource();

    return (
      <>
        <div className="dw-home-left-panel-title">
          <b>DIGITAL WINDMILL</b>
        </div>
        <img src="/img/fuwlogo.jpg" width="58%" alt="Friends of Upminster Windmill Logo" />
        <div className="dw-home-left-lower-wording">
          A showcase of digital media<br /> produced by the<br />
          <b>Friends of Upminster Windmill</b>
        </div>
        {(this.source !== "groundFloor") &&
          <div className="dw-home-left-touch-screen-instruction">
            <img src="/img/touchiconwhite.png" height={50 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale')} className="dw-home-left-touch-screen-icon" alt="Touch"></img>
            <div className="dw-home-left-touch-screen-text">Touch the screen to explore</div>
          </div>
        }
        {(this.source === "groundFloor") &&
          <div className="dw-home-left-touch-return-to-home-screen-instruction" onClick={() => { this.onReturnToGroundFloorHomeScreenClick() }}>
            <img src="/img/touchiconwhite.png" height={50 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale')} className="dw-home-left-touch-screen-icon" alt="Touch"></img>
            <div className="dw-home-left-touch-screen-text">Return to welcome screen</div>
          </div>
        }

      </>
    )
  }
}

export { HomeLeftPanel }



